import { JwtHelperService } from '@auth0/angular-jwt';

export function isJwtTokenExpired(token: string): boolean {
  if (!token) {
    return true;
  }
  const jwtHelperService: JwtHelperService = new JwtHelperService();
  let isTokenExpired: boolean;
  try {
    isTokenExpired = jwtHelperService.isTokenExpired(token) as boolean;
  } catch (e) {
    isTokenExpired = true;
  }
  return isTokenExpired;
}
