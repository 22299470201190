export enum RouteSlug {
  LIVE_CHAT = 'live-chat',

  MAIN = '',

  AUTH = 'auth',
  LOGIN = 'login',
  RESET_PASSWORD = 'reset-password',
  REGISTRATION = 'registration',
  ACTIVATE_ACCOUNT = 'activate',
  CHANGE_FORGOT_PASSWORD = 'change-password',
  LOREAL_EMPLOYEE_CHANGE_FORGOT_PASSWORD = 'loreal-client-change-password',
  LOREAL_EMPLOYEE_ACTIVATE_ACCOUNT = 'loreal-client-activate-account',
  AUTH_CONTACT = 'auth-contact',
  ONKO = 'onko',

  ACCOUNT = 'account',
  CHALLENGES = 'challenges',
  BENEFITS = 'benefits',
  BENEFIT = 'benefit',
  CONTESTS = 'contests',
  CONTACT = 'contact',

  TRAININGS = 'trainings',
  WEBINARS = 'dermopartnertalk',
  SCHOOLING = 'schooling',
  PRODUCTS = 'products',
  PRODUCT = 'product',
  CATEGORY = 'category',
  COMPONENT = 'component',
  GAMA = 'acd-intro',

  DATA = 'data',
  MY_PROGRESS = 'my-progress',
  MY_LIVE_EVENTS = 'my-live-events',
  TRAINING_FOR_EDUCATIONAL_POINTS = 'training-for-educational-points',
  MY_DATA = 'my-data',
  SURVEY = 'survey',
  TRAINING_HISTORY = 'training-history',

  // ACCOUNT EDU TRAININGS
  ACCOUNT_ACADEMY = 'academy',
  ACCOUNT_ACADEMY_FAQ = 'faq',
  ACCOUNT_VIDEO_TRAININGS = 'video-trainings',
  LIVE_EVENTS = 'wydarzenia-live',
  QUIZ = 'quiz',
  INTERACTIVE_TRAINING = 'szkolenieinteraktywne',
  //

  // LP
  PHARMACIST_TEST = 'testfarmaceuty',
  SCIENCE_PHARMA = 'science-pharma',
  SCIENCE_PHARMA_VIDEO = 'science-pharma-video',
  PHARMACIST_TEST_REPORT = 'testfarmaceuty-raport',
  PHARMACIST_TEST_REPORT_MELA_B3 = 'testfarmaceuty-raport-melab3',
  CONSENSUS_INFOGRAPHIC = 'infografika_konsensus',
  MMS_COMMAND_PROTOCOLS = 'protokoly_polecen',
  LIFTACTIV_COMMAND_PROTOCOLS = 'protokoly_polecen_liftactiv',
  SUN_COMPETITION = 'bohaterowie-lata',
  VICHY3_COMPETITION = 'skora-dojrzala',
  VICHY_CONTACT_FORM = 'konkurs-vichy-formularz-kontaktowy',
  VICHY_INTEGRATIVE_CENTE = 'vichy-integrative-cente',
  ONKOCARE = 'onkocare',
  // END LP

  EUD_TRAININGS_IN_PROGRESS = 'szkolenia-w-trakcie-modernizacji',

  EDU_TRAININGS = 'edu-trainings',

  NOT_FOUND_PAGE = '404',
  COOKIES_POLICY = 'cookies-policy',
  PRIVACY_POLICY = 'privacy-policy',
  REGULATIONS = 'regulations',

  SSR_REDIRECT = 'ssr-redirect',
}
